* {
	box-sizing: border-box;
}

html {
	overflow-x: hidden;
}

body {
	background-color: var(--bgcolor) !important;
	color: var(--color);
}

h1,
h3,
h4,
h5,
h6,
p,
a,
.table-of-contents a,
span,
.grey-text.text-lighten-3,
.grey-text.text-lighten-1,
.service-title,
li,
time,
div {
	color: var(--color) !important;
}

.ct-widget .ct-tool {
	color: #464646 !important;
}

h2 {
	color: var(--heading-color) !important;
}

.blocks a .title,
.blocks .story-meta,
.blocks .story-meta time,
.blocks .story-name {
	color: var(--title-color) !important;
}

picture {
	&,
	img {
		display: block;
	}

	source {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.slides {
	picture,
	picture img {
		height: 100%;
		display: block;
		object-fit: cover;
	}
}
