.contact_form {
	$self: &;

	&__column,
	&__row {
		display: flex;
	}

	&__column {
		flex-wrap: wrap;
	}

	&__row {
		margin-right: -1 * map-get($padding, "column-mobile");
		margin-left: -1 * map-get($padding, "column-mobile");
		flex-direction: column;

		@include media-breakpoint-up($bpUp) {
			flex-direction: row;
			margin-right: -1 * map-get($padding, "column-desktop");
			margin-left: -1 * map-get($padding, "column-desktop");
		}
	}

	&__column {
		flex: 1 1 10%;

		#{$self}__field {
			width: 100%;
		}
	}

	&__field {
		display: flex;
		flex-direction: column;
		margin-right: map-get($padding, "column-mobile");
		margin-left: map-get($padding, "column-mobile");

		@include media-breakpoint-up($bpUp) {
			margin-right: map-get($padding, "column-desktop");
			margin-left: map-get($padding, "column-desktop");
		}

		&:not(:last-child) {
			margin-bottom: 1rem;
		}

		&.radio,
		&.checkbox {
			label {
				cursor: pointer;
				position: relative;
				padding-left: 2.2rem;
				display: block;
			}
		}

		&--error {

			input,
			.selector,
			.radio-check__input,
			.file__drop {
				border-color: red !important;
			}
		}
	}

	.hide {
		display: none;
	}

	input[type=email],
	input[type=text],
	textarea.materialize-textarea {
		border-color: var(--color);

	}
	.input-field label {

		color: var(--color);
	}
	input, textarea {
		color: var(--color);

		&::-webkit-input-placeholder {
			color: var(--color);
		}
	
		&::-moz-placeholder {
			color: var(--color);
		}
	
		&:-ms-input-placeholder {
			color: var(--color);
		}
	
		&:-moz-placeholder {
			color: var(--color);
		}

	}
}

.notification {
	position: relative;

	.message {
		opacity: 0;
		@include transition;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.success {
		.message__success {
			opacity: 1;
		}
	}

	&.error {
		.message__error {
			opacity: 1;
		}
	}
}

.grecaptcha-badge {
	display: none;
}

[hidden] {
	display: block !important;
}

.selector {
	$self: &;
	cursor: pointer;
	position: relative;
	z-index: 5;

	&__wrapper {
		select {
			display: none;
		}
	}

	&__input {
		padding: 1rem;
		background: lightgrey;
		display: inline-flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		svg {
			margin-left: 2rem;
			height: 1rem;
			pointer-events: none;
			@include transition;
		}
	}

	&__value {
		display: none;
	}

	&__list {
		position: absolute;
		bottom: 0;
		width: 100%;
		transform: translateY(100%);
		max-height: 0;
		@include transition;
		overflow: hidden;

		&__item {
			padding: 1rem;
			background: rgba(240, 240, 240, 1);
		}
	}

	&--open {
		z-index: 11;

		#{$self}__list {
			max-height: 200px;
		}

		svg {
			transform: rotate(180deg);
		}
	}

	&--closing {
		z-index: 11;
	}

	&--selected {
		#{$self}__value {
			display: block;
		}

		#{$self}__placeholder {
			display: none;
		}
	}
}

.file {
	&__drop {
		position: relative;
	}

	&__overlay {
		pointer-events: none;
		@include cover;
	}

	input {
		opacity: 0;
		cursor: pointer;
		width: 100%;
		height: 100%;
	}

	.drag_over {
		border-style: dashed;
	}

	.has_file {
		background: green;
	}

	&__remove {
		cursor: pointer;
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
	}
}

$radioCheckSize: 2rem;

.radio-check {
	$radioCheck: &;
	display: flex;
	align-items: center;

	&--radio {
		#{$radioCheck} {
			&__input {
				border-radius: 50%;

				&::before {
					border-radius: 50%;
				}
			}
		}
	}

	&__input {
		margin-left: -$radioCheckSize;
		margin-right: 1rem;
	}

	input,
	&__input {
		position: relative;
		@include box($radioCheckSize);
	}

	&__input {
		border: 1px solid map-get($colors, black);

		&::before {
			@include transition;
			width: 80%;
			height: 80%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			content: "";
			background-color: transparent;
		}
	}

	input {
		opacity: 0;
		z-index: 10;
		position: relative;

		&:checked {
			&+.radio-check__input {
				&::before {
					background-color: map-get($colors, primary);
				}
			}
		}
	}
}