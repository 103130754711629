@include media-breakpoint-down(sm) {
	.slider {
		.slides {
			pointer-events: none;
		}
		.indicators {
			bottom: 10px;

			.indicator-item {
				height: 10px;
				width: 10px;
				margin: 0 7px;
			}
		}
	}
}
