.p {
	&-t {
		&--none {
			padding-top: 0 !important;
		}
		@include sectionSpace(top, 5rem);
	}

	&-b {
		&--none {
			padding-bottom: 0 !important;
		}

		@include sectionSpace(bottom, 5rem);
	}

	.m {
		&-t {
			&--none {
				margin-top: 0 !important;
			}
			@include sectionSpace(top, 5rem, margin);
		}

		&-b {
			&--none {
				margin-bottom: 0 !important;
			}

			@include sectionSpace(bottom, 5rem, margin);
		}
	}
}
