body,
button,
input,
select,
textarea {
	-webkit-font-smoothing: antialiased;
	font-family: map-get($fonts, primary);
}

ul {
	list-style: none;

	&,
	li {
		padding: 0;
	}
}
