nav {
	a {
		@include media-breakpoint-down(md) {
			justify-content: center;
			width: 100%;
		}
	}
	img {
		height: 2.8rem;
		margin-bottom: -1rem;
	}

	.container {
		height: 100%;
	}
}

.show-on-medium-and-down {
	@include media-breakpoint-down(md) {
		position: absolute;
		right: 5%;
	}
}