.white_space {
	width: 100%;

	&--small {
		height: map-get($padding, "small");

		@include media-breakpoint-up($bpUp) {
			height: map-get($padding, "small");
		}
	}

	&--medium {
		height: map-get($padding, "medium");

		@include media-breakpoint-up($bpUp) {
			height: map-get($padding, "medium");
		}
	}

	&--large {
		height: map-get($padding, "large");

		@include media-breakpoint-up($bpUp) {
			height: map-get($padding, "large");
		}
	}

	&.hide_mobile {
		display: none;

		@include media-breakpoint-up($bpUp) {
			display: block;
		}
	}

	&.hide_desktop {
		@include media-breakpoint-up($bpUp) {
			display: none;
		}
	}
}
